@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: local('Inter'), url('./assets/fonts/Inter.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Brush';
    font-weight: 400;
    src: local('Brush'), url('./assets/fonts/Brush.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: local('Montserrat'),
      url('./assets/fonts/Montserrat.ttf') format('truetype');
  }
}

@layer components {
  .banner-background {
    background: linear-gradient(107.15deg, #bc1e1e 0%, #f6ac0d 100%);
  }

  @media screen and (max-width: 600px) {
    .faq-width {
      width: 300px;
    }
  }

  @media screen and (min-width: 600px) {
    .faq-width-lg {
      width: 600px;
    }
  }
}
